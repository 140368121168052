import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

const StyledWrapper = styled.div`
  padding: 114px 0px 50px;
  background-color: ${props => `${props.theme.primeBluePalette.original}EB`};
  color: white;

  > div {
    @media only screen and (min-width: 768px) {
      margin: 20px 0px;
    }
  }

  h4 {
    color: white;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 1.75rem;
    color: ${props => props.theme.primeYellowPalette.original};
  }

  img {
    max-width: 100%;
    margin: 0;
    @media only screen and (max-width: 768px) {
      padding: 6px;
    }
  }
`

function HeroSectionComponent() {
  return (
    <StyledWrapper>
      <Row type="flex" align="middle" justify="center">
        <Col
          xs={{ span: 22, order: 1 }}
          sm={{ span: 22, order: 1 }}
          md={{ span: 14, order: 0 }}
          lg={{ span: 12, order: 0 }}
          xl={{ span: 12, order: 0 }}
          xxl={{ span: 11, order: 0 }}
          style={{ textAlign: 'center' }}
        >
          <h1>
            2020-2021 Philippine Real Estate Business Intelligence Compendium
          </h1>
          <div style={{ margin: '14px 0px' }}>
            <h4>FIRST REAL ESTATE-FOCUSED SURVEY REPORT IN THE PHILIPPINES</h4>
            <p style={{ marginBottom: 0 }}>
              Make informed investment decisions by getting exclusive access to
              the latest trends and movement in the Philippine real estate
              industry amidst the COVID-19 pandemic. Spanning three volumes,
              this extensive research compendium covers real estate insights,
              regional market updates, economic snapshots of the Philippines,
              and more.
            </p>
          </div>
        </Col>
        {/* <Col
          xs={{ span: 18, order: 0 }}
          sm={{ span: 18, order: 0 }}
          md={{ span: 7, offset: 1 }}
          lg={{ span: 5, offset: 1 }}
          xl={{ span: 5, offset: 1 }}
          xxl={{ span: 4, offset: 1 }}
        >
          <img
            src="../../images/all-volume-package.png"
            alt="REAL ESTATE-FOCUSED SURVEY REPORT IN THE PHILIPPINES"
          />
        </Col> */}
      </Row>
    </StyledWrapper>
  )
}

export default HeroSectionComponent
