import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  Row,
  Col,
  Input,
  Button,
  Radio,
  notification,
  Modal,
  Checkbox,
} from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import queryString from 'query-string'

// import PriceRateComponent from './PriceRateComponent'

const { Group } = Radio
const { TextArea } = Input

const StyledRow = styled(Row)`
  padding: 70px 0px;
`

const StyledContactForm = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .contact-header {
    padding: 0px 8px;
  }

  .styled-row {
    margin: 12px 0px;
    width: 100%;

    .category {
      font-size: 18px;
      font-weight: 600;
    }

    .ant-divider-horizontal {
      margin: 12px 0px 24px 0px;
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      > div:first-child {
        font-weight: bold;
        opacity: 0.75;
      }

      .disclaimer {
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
        color: ${props => props.theme.whitePalette.dark};
      }

      .required {
        color: ${props => props.theme.primeRedPalette.original};
      }

      .radio-group {
        display: flex;
        flex-direction: column;

        .ant-radio-wrapper {
          margin: 8px 0px;
        }
      }

      .styled-button {
        height: auto;
        padding: 8px !important;
      }
    }

    .input-wrapper:nth-last-child(n + 2) {
      margin-bottom: 18px;
    }
  }

  .volume-selection {
    .ant-radio-wrapper {
      white-space: normal;
    }
  }

  .warning-span {
    color: #ff0000;
  }
`

const StyledPopup = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }

  .message {
    margin-bottom: 10px;
  }

  .message:first-child {
    font-weight: 600;
  }

  .bank-details-wrapper {
    margin-top: 14px;
    background-color: #fafafa;
    padding: 16px;
    border-radius: 8px;

    .name {
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: 600;
      line-height: 24px;
    }

    .value {
      font-weight: 600;
    }
  }
`

function PersonalDetails({
  handlers,
  value,
  errorFields,
  isLoading,
  urlParams,
  isUsd,
  setVolumeSelected,
}) {
  const recaptchaRef = useRef(null)

  return (
    <Row type="flex" className="styled-row " gutter={16}>
      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.firstname) ? 'has-error' : ''
        }`}
      >
        <div>
          <span className="required">*</span> First Name
        </div>
        <Input
          value={value.firstname}
          placeholder="John"
          onChange={e => handlers.firstnameOnChange(e)}
        />
        {errorFields.find(err => err.firstname) ? (
          <div className="error-explain">
            {errorFields.find(err => err.firstname).firstname}
          </div>
        ) : null}
      </Col>

      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.lastname) ? 'has-error' : ''
        }`}
      >
        <div>
          <span className="required">*</span> Last Name
        </div>
        <Input
          placeholder="Doe"
          value={value.lastname}
          onChange={e => handlers.lastnameOnChange(e)}
        />
        {errorFields.find(err => err.lastname) ? (
          <div className="error-explain">
            {errorFields.find(err => err.lastname).lastname}
          </div>
        ) : null}
      </Col>

      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.contactNo) ? 'has-error' : ''
        }`}
      >
        <div>
          <span className="required">*</span> Contact No.
        </div>
        <Input
          value={value.contactNo}
          placeholder="+63 9123456789"
          onChange={e => handlers.contactNoOnChange(e)}
        />
        {errorFields.find(err => err.contactNo) ? (
          <div className="error-explain">
            {errorFields.find(err => err.contactNo).contactNo}
          </div>
        ) : null}
      </Col>

      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.email) ? 'has-error' : ''
        }`}
      >
        <div>
          <span className="required">*</span> Email
        </div>
        <Input
          value={value.email}
          placeholder="example@gmail.com"
          onChange={e => handlers.emailOnChange(e)}
        />
        {errorFields.find(err => err.email) ? (
          <div className="error-explain">
            {errorFields.find(err => err.email).email}
          </div>
        ) : null}
      </Col>

      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.jobTitle) ? 'has-error' : ''
        }`}
      >
        <div>
          <span className="required">*</span> Job Title
        </div>
        <Input
          value={value.jobTitle}
          placeholder="Manager"
          onChange={e => handlers.jobTitleOnChange(e)}
        />
        {errorFields.find(err => err.jobTitle) ? (
          <div className="error-explain">
            {errorFields.find(err => err.jobTitle).jobTitle}
          </div>
        ) : null}
      </Col>

      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.companyName) ? 'has-error' : ''
        }`}
      >
        <div>
          <span className="required">*</span> Company Name
        </div>
        <Input
          value={value.companyName}
          onChange={e => handlers.companyNameOnChange(e)}
          placeholder="PRIME Philippines"
        />
        {errorFields.find(err => err.companyName) ? (
          <div className="error-explain">
            {errorFields.find(err => err.companyName).companyName}
          </div>
        ) : null}
      </Col>

      <Col sm={12} xs={24} className="input-wrapper">
        <div>
          <span className="required">*</span> Which volume you want to
          subscribe?
        </div>
        <Group
          className="radio-group volume-selection"
          onChange={e => handlers.selectedVolumeOnChange(e)}
          value={value.volume}
        >
          {volumesList.map(v => (
            <Radio key={v.title} value={v.title} disabled={v.disabled}>
              <span dangerouslySetInnerHTML={{ __html: v.title }} />
            </Radio>
          ))}
        </Group>
      </Col>

      <Col sm={12} xs={24} className="input-wrapper">
        <div>
          <span className="required">*</span> How did you know about the
          subscription package?
        </div>
        <Group
          className="radio-group"
          onChange={e => handlers.knowAboutOnChange(e)}
          value={value.knowAbout}
        >
          <Radio value="Social Media i.e. Facebook, LinkedIn">
            Social Media i.e. Facebook, LinkedIn
          </Radio>
          <Radio value="Email">Email, newsletter</Radio>
          <Radio value="Through a PRIME professional">
            Through a PRIME professional
            {value.knowAbout === 'Through a PRIME professional' && (
              <div
                style={{ marginLeft: 24 }}
                className={
                  errorFields.find(err => err.answer) ? 'has-error' : ''
                }
              >
                <TextArea
                  value={value.answer}
                  placeholder="Tell us more about it"
                  onChange={e => handlers.answerOnChange(e)}
                />
              </div>
            )}
          </Radio>
          <Radio value="Others">
            Others
            {value.knowAbout === 'Others' && (
              <div
                style={{ marginLeft: 24 }}
                className={
                  errorFields.find(err => err.answer) ? 'has-error' : ''
                }
              >
                <TextArea
                  value={value.answer}
                  placeholder="Tell us more about it"
                  onChange={e => handlers.answerOnChange(e)}
                />
              </div>
            )}
          </Radio>
        </Group>
      </Col>

      <Col sm={24} xs={24} className="input-wrapper">
        <div>
          <Checkbox
            disabled={value.isPreviewDisabled}
            checked={value.isPreviewSelected}
            onChange={e => {
              handlers.isPreviewSelectedChange(e.target.checked)
            }}
            style={{ color: 'rgba(0, 0, 0, 0.9)' }}
          >
            I want to receive a free preview of this volume in my email.
          </Checkbox>
        </div>
      </Col>

      <Col
        sm={12}
        xs={24}
        className={`input-wrapper ${
          errorFields.find(err => err.recaptcha) ? 'has-error' : ''
        }`}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LefLjcUAAAAAMYZk7A86WIJp10jv7kLSRU80_NT"
          onChange={e => handlers.recaptchaOnChange(e)}
          onExpired={() => handlers.recaptchaOnChange('')}
          onErrored={() => handlers.recaptchaOnChange('')}
        />

        {errorFields.find(err => err.recaptcha) ? (
          <div className="error-explain" style={{ marginBottom: 0 }}>
            {errorFields.find(err => err.recaptcha).recaptcha}
          </div>
        ) : null}
      </Col>
      <Col sm={12} xs={0} />
      <Col sm={12} xs={24} className="input-wrapper">
        <Button
          loading={isLoading}
          type="primary"
          className="styled-button"
          onClick={() => handlers.onSubmit()}
        >
          Subscribe Now
        </Button>
      </Col>
    </Row>
  )
}

const volumesList = [
  {
    title:
      'NAVIGATING THROUGH TIDES: Philippine Real Estate Outlook and Sentiments amid Lockdown (<i>Volume 1</i>)',
    disabled: false,
  },
  {
    title: `THE ROAD TO RECOVERY: Real Estate Sentiments and Strategy Conspectus (<i class='warning-span'>Volume 2, New Issue</i>)`,
    disabled: false,
  },
  {
    title:
      'BREAKING BOUNDARIES: Philippines Next Wave Cities Real Estate Market and Outlook Report 2021 (<i>Coming Soon</i>)',
    disabled: true,
  },
]

const ContactFormComponent = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [isUsd, setIsUsd] = useState(true)
  const [onPopup, setOnPopup] = useState(false)
  const [details, setDetails] = useState({
    firstname: '',
    lastname: '',
    contactNo: '',
    email: '',
    jobTitle: '',
    companyName: '',
    volume: volumesList[1].title,
    knowAbout: 'Social Media i.e. Facebook, LinkedIn',
    answer: '',
    recaptcha: '',
    isPreviewSelected: true,
    isPreviewDisabled: false,
  })

  const [errorFields, setErrorFields] = useState([])

  const firstnameOnChange = e => {
    setDetails({ ...details, firstname: e.target.value })
  }

  const lastnameOnChange = e => {
    setDetails({ ...details, lastname: e.target.value })
  }

  const contactNoOnChange = e => {
    setDetails({ ...details, contactNo: e.target.value })
  }

  const emailOnChange = e => {
    setDetails({ ...details, email: e.target.value })
  }

  const jobTitleOnChange = e => {
    setDetails({ ...details, jobTitle: e.target.value })
  }

  const companyNameOnChange = e => {
    setDetails({ ...details, companyName: e.target.value })
  }

  const knowAboutOnChange = e => {
    setDetails({ ...details, knowAbout: e.target.value, answer: '' })

    setErrorFields(
      errorFields.filter(value => Object.keys(value)[0] !== 'answer')
    )
  }

  const selectedVolumeOnChange = e => {
    setDetails({
      ...details,
      volume: e.target.value,
      answer: '',
      isPreviewDisabled:
        volumesList.findIndex(v => v.title === e.target.value) === 0,
      isPreviewSelected:
        volumesList.findIndex(v => v.title === e.target.value) === 1,
    })
  }

  const answerOnChange = e => {
    setDetails({ ...details, answer: e.target.value })
  }

  const currencyOnChange = e => {
    setIsUsd(e)
  }

  const recaptchaOnChange = e => {
    setDetails({ ...details, recaptcha: e })
  }

  const isPreviewSelectedChange = e => {
    setDetails({ ...details, isPreviewSelected: e })
  }

  const onSubmit = () => {
    let requiredFields = Object.entries(details)
      .filter(value =>
        [
          'firstname',
          'lastname',
          'contactNo',
          'email',
          'jobTitle',
          'companyName',
          'recaptcha',
        ].includes(value[0])
      )
      .filter(value => `${value[1]}`.trim() === '')
      .map(value => ({ [`${value[0]}`]: 'This field is required.' }))

    if (
      details.knowAbout === 'Others' ||
      details.knowAbout === 'Through a PRIME professional'
    ) {
      if (`${details.answer}`.trim() === '') {
        requiredFields = [
          ...requiredFields,
          { answer: 'This field is required' },
        ]
      }
    }

    setErrorFields(requiredFields)

    if (requiredFields.length > 0) {
      notification.warning({
        message: 'Incomplete Fields',
        description: 'Please complete all the required fields.',
      })
    } else {
      const volTitle = details.volume
        .replace(' (<i>Volume 1</i>)', '')
        .replace(` (<i class='warning-span'>Volume 2, New Issue</i>)`, '')
        .replace(` (<i>Coming Soon</i>)`, '')
      let previewPdfData = {}

      if (
        props.volumes.findIndex(
          v => v.title.toLowerCase() === volTitle.toLowerCase()
        ) === 1
      ) {
        previewPdfData = {
          type: 'publication',
          preview_title: volTitle,
          preview_img_path: props.volumes[1].pdfPreviewImg,
          preview_description: props.volumes[1].description,
          file_link: props.volumes[1].pdfFile,
        }
      }

      console.log(
        'details',
        props.volumes.findIndex(
          v => v.title.toLowerCase() === details.volume.toLowerCase()
        ) === 1
      )
      console.log('previewPdfData', previewPdfData)

      setIsLoading(true)
      fetch(`https://hephaestus-backend.herokuapp.com/email-requests`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_identifier: 'PRIMEPH-PREBIC',
          email_address: details.email,
          subject: 'Real Estate Business Intelligence Inquiry',
          ...previewPdfData,
          data: [
            {
              key: 'Fullname',
              value: `${details.firstname} ${details.lastname}`,
            },
            { key: 'Email Address' },
            { key: 'Contact Number', value: details.contactNo },
            { key: 'Company Name', value: details.companyName },
            { key: 'Job Title', value: details.jobTitle },
            {
              key: 'Volume Selected',
              value: volTitle,
            },
            {
              key: 'How did you know about the subscription package?',
              value: `${details.knowAbout}${
                details.answer ? `: ${details.answer}` : ''
              }`,
            },
            {
              key: 'Download Preview is checked?',
              value: details.isPreviewSelected,
            },
          ],
        }),
      })
        .then(response => response.json())
        .then(() => {
          setDetails({
            firstname: '',
            lastname: '',
            contactNo: '',
            email: '',
            jobTitle: '',
            companyName: '',
            knowAbout: 'Social Media i.e. Facebook, LinkedIn',
            volume: volumesList[1].title,
            isPreviewSelected: true,
            answer: '',
            recaptcha: '',
          })
          setErrorFields([])
          setOnPopup(true)
        })
        .catch(() => {
          notification.error({
            message: 'Something Went Wrong!',
            description:
              'There was probleming sending your subscription. Please try again later.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const onClosePopup = () => setOnPopup(false)

  const urlParams = queryString.parse(props.location.search)

  return (
    <StyledRow type="flex" justify="center" align="middle" id="form">
      <Col xxl={14} xl={16} lg={18} md={20} sm={20} xs={22}>
        <StyledContactForm>
          <div className="contact-header">
            <h2>Subscription Form</h2>
            <p>
              Our team will reach you through the details you provided. Please
              make sure they are correct and up-to-date.
            </p>
          </div>
          <PersonalDetails
            isLoading={isLoading}
            errorFields={errorFields}
            handlers={{
              firstnameOnChange,
              lastnameOnChange,
              contactNoOnChange,
              emailOnChange,
              jobTitleOnChange,
              companyNameOnChange,
              knowAboutOnChange,
              selectedVolumeOnChange,
              answerOnChange,
              currencyOnChange,
              recaptchaOnChange,
              onSubmit,
              isPreviewSelectedChange,
            }}
            isUsd={isUsd}
            value={details}
            urlParams={urlParams}
          />
        </StyledContactForm>
        <StyledPopup
          visible={onPopup}
          closable={false}
          keyboard={false}
          footer={
            <Button type="primary" onClick={() => onClosePopup()}>
              Done
            </Button>
          }
        >
          <Row type="flex">
            <Col span={24} className="message">
              Thank you for submitting your subscription form. Our team will
              reach you through the details you provided. You will also receive
              an email for the billing statement through the email address
              provided.
            </Col>
            <Col span={24} className="message">
              PRIME Philippines has two bank accounts which you may deposit
              payments to, for your convenience:
            </Col>
            <Col span={24} className="bank-details-wrapper">
              <div className="name">
                Property Interactive Marketing Enterprise Realty Corporation
              </div>
              <div>
                Bank: <span className="value">Metrobank</span>
              </div>
              <div>
                Branch:{' '}
                <span className="value">
                  Examiner-Quezon Avenue, Quezon City
                </span>
              </div>
              <div>
                Account Number: <span className="value">636-763-602-8769</span>
              </div>
            </Col>
            <Col span={24} className="bank-details-wrapper">
              <div className="name">
                Property Interactive Marketing Enterprise Realty Corporation
              </div>
              <div>
                Bank: <span className="value">Banco De Oro</span>
              </div>
              <div>
                Branch:{' '}
                <span className="value">
                  Sct. Albano-Quezon Avenue, Quezon City
                </span>
              </div>
              <div>
                Account Number: <span className="value">003-938-019-810</span>
              </div>
            </Col>
          </Row>
        </StyledPopup>
      </Col>
    </StyledRow>
  )
}

ContactFormComponent.propTypes = {}

export default ContactFormComponent
