import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactFormComponent'
import HeroSectionComponent from '../components/HeroSectionComponent'
import NavigatingThroughTidesComponent from '../components/NavigatingThroughTidesComponent'
import OtherVolumesComponent from '../components/OtherVolumesComponent'

import ogCover from '../../static/images/og-cover-volume1.jpg'

export const volumes = {
  no1: {
    released: 'VOLUME 1 (April 17, 2020)',
    title:
      'NAVIGATING THROUGH TIDES: PHILIPPINE REAL ESTATE OUTLOOK AND SENTIMENTS AMID LOCKDOWN',
    description: `Envisioned to provide a clear snapshot of the current real estate market, this volume highlights the sentiments and insights of nearly 500 landlords, occupiers and investors on the impact of COVID-19 in the overall economic and real estate business in the Philippines.`,
    imgSrc: '../../images/vol1-bg-min.jpg',
    slug: '/navigating-through-tides-volume-1',
  },
  no2: {
    released: 'VOLUME 2 (September 2020, New Issue)',
    title:
      'THE ROAD TO RECOVERY: REAL ESTATE SENTIMENTS AND STRATEGY CONSPECTUS',
    description: `Analyzing collective insights from real estate practitioners from various sectors – office, retail, residential, hotel, and industrial, this report focuses on the strategies stakeholders may apply to recuperate from the adverse effects of the COVID-19 outbreak and how to move forward to sustain long-term business continuity.`,
    imgSrc: '../../images/vol2-bg-min.jpg',
    pdfPreviewImg:
      'https://assets.primephilippines.com/images/publication-covers/road-to-recovery.png',
    pdfFile:
      'https://assets.primephilippines.com/pdfs/publications/PRIME_Philippines_Compendium_Vol_2_Free_Version.pdf',
    slug: '/',
  },
  no3: {
    released: 'VOLUME 3 (2021, for subscribers only)',
    title:
      'BREAKING BOUNDARIES: PHILIPPINES NEXT WAVE CITIES REAL ESTATE MARKET AND OUTLOOK REPORT 2021',
    description:
      'Brimming with high-level market updates from the next wave cities in the Philippines, this volume aims to equip landlords, occupiers, and investors/developers with critical market movements, forecasts and sentiments on the emerging hotspots outside the traditional business districts in the Philippines.',
    imgSrc: '../../images/vol3-bg-min.png',
  },
}

const StyledDivWrapper = styled.div`
  background: #003366;
  position: relative;
  z-index: 0;
`

const IndexPage = props => (
  <Layout>
    <SEO
      title="2020-2021 Philippine Real Estate Business Intelligence Compendium"
      description="Learn the sentiments and insights of nearly 500 landlords, occupiers and investors on the impact of COVID-19 in the overall economic and real estate business in the Philippines."
      meta={[
        {
          property: `og:image`,
          content: ogCover,
        },
        {
          name: `keywords`,
          content: `
          Philippine Real Estate Business Intelligence Compendium,
          NAVIGATING THROUGH TIDES PHILIPPINE REAL ESTATE OUTLOOK AND SENTIMENTS AMID LOCKDOWN,
          Global Market and Business Situationer,
          Philippine Real Estate Business Performance,
          Philippine Real Estate Business Outlook,
          Property Sector Bull and Bear Meter,
          Geospatial Investor Confidence Mapping,
          Real Property Buy-Sell Spectrum,
          Occupier and Investor Business Continuity Overview,
          LEVELLING WATERS POST-COVID-19 SENTIMENTS AND STRATEGY CONSPECTUS,
          BREAKING BOUNDARIES PHILIPPINES NEXT WAVE CITIES REAL ESTATE MARKET AND OUTLOOK REPORT 2021,
          PRIME Philippines
          `,
        },
      ]}
    />

    <StyledDivWrapper>
      <HeroSectionComponent />
      <NavigatingThroughTidesComponent />
      <OtherVolumesComponent volumes={[volumes.no2, volumes.no3]} {...props} />
    </StyledDivWrapper>
    <ContactForm volumes={[volumes.no1, volumes.no2, volumes.no3]} {...props} />
  </Layout>
)

export default IndexPage
