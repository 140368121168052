import React from 'react'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import queryString from 'query-string'

import pledgeBackgroundImage from '../../static/images/our-pledge-bg.jpg'

const StyledDiv = styled.div`
  position: relative;
  background: ${props => `${props.theme.primeBluePalette.original}EB`};
`

const StyledOtherVolume = styled.div`
  padding: 60px 0px 50px;

  .blue-overlay {
    .single-volume-link {
      color: ${props => props.theme.primeYellowPalette.original};
      font-size: 18px;
      font-weight: 600;
    }

    .col-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .styled-image {
        height: 420px;
        margin: 8px;

        @media screen and (max-width: 767px) {
          height: 300px;
        }
      }

      .styled-card {
        position: relative;
        height: 100%;
        > div {
          padding: 24px;
          height: 100%;
          background: rgba(0, 0, 0, 0.65);
          position: relative;
          z-index: 1;
          color: ${props => props.theme.whitePalette.original};
        }
      }
      .volume-released {
        font-style: italic;
        font-size: 14px;
        margin-bottom: 14px;
      }

      .volume-title {
        color: ${props => props.theme.primeYellowPalette.original};
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
      }

      .vol-1::after {
        z-index: 0;
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: ${props => `url('${props.bgImg1}')`};
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .vol-3::after {
        z-index: 0;
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: ${props => `url('${props.bgImg2}')`};
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .content {
        display: flex;
        flex-direction: column;
        > div:first-child {
          flex: 1;
        }

        .cta {
          background-color: #fad127;
          border-color: #fad127;
          color: #003366;
          :hover {
            opacity: 0.8;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .col-volume2 {
        flex-direction: column-reverse;
      }

      .col-volume3 {
        flex-direction: column;
      }
    }

    .col-volume3 {
      @media screen and (max-width: 426px) {
        margin-top: 50px;
      }
    }
  }
`

const StyledOurPledge = styled.div`
  padding: 50px 0px 50px;
  background-image: url(${pledgeBackgroundImage});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 16%, 100% 0, 100% 100%, 0% 100%);

  @media screen and (max-width: 426px) {
    clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
  }

  .card-overlay {
    background-color: ${props => `${props.theme.whitePalette.original}CC`};
    padding: 48px;
    margin: 48px 8px 0px 8px;

    .card-title {
      font-weight: 700;
      color: ${props => props.theme.primeBluePalette.original};
    }

    .card-description {
      line-height: 28px;
      margin-left: 14px;

      .highlighted {
        font-weight: 600;
        color: ${props => props.theme.primeBluePalette.original};
      }
    }

    @media screen and (max-width: 767px) {
      padding: 24px;
    }
  }

  @media screen and (max-width: 767px) {
    bottom: -24%;
  }

  @media screen and (max-width: 576px) {
    bottom: -20%;
  }
`
const OtherVolumeSection = ({ urlParams, volumes }) => (
  <StyledOtherVolume bgImg1={volumes[0].imgSrc} bgImg2={volumes[1].imgSrc}>
    <div className="blue-overlay">
      <Row type="flex" justify="center" align="middle" className="styled-row">
        <Col xs={22} sm={22} md={23} lg={20} xl={18} xxl={14}>
          <Row type="flex" gutter={[40, 20]} align="stretch">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-wrapper col-volume2"
            >
              <div className="styled-card vol-1">
                <div className="content">
                  <div>
                    <div className="volume-title">{volumes[0].title}</div>
                    <div className="volume-released">{volumes[0].released}</div>
                    <p className="volume-description">
                      {volumes[0].description}
                    </p>
                  </div>
                  <Button
                    type="primary"
                    size="large"
                    className="cta"
                    href={volumes[0].slug}
                  >
                    <b>LEARN MORE</b>
                  </Button>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-wrapper col-volume3"
            >
              <div className="styled-card vol-3">
                <div className="content">
                  <div>
                    <div className="volume-title">{volumes[1].title}</div>
                    <div className="volume-released">{volumes[1].released}</div>
                    <p className="volume-description">
                      {volumes[1].description}
                    </p>
                  </div>
                  <Button type="primary" size="large" disabled>
                    <b>COMING SOON</b>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </StyledOtherVolume>
)

const OurPledgeSection = () => (
  <StyledOurPledge>
    <Row type="flex" justify="center" align="middle" className="styled-row">
      <Col lg={18} md={20} sm={20} xs={22}>
        <Col lg={12} md={16} sm={18} xs={24} className="card-overlay">
          <h2 className="card-title">Our Pledge</h2>
          <div className="card-description">
            To support the precarious mission of our front-liners in battling
            COVID-19 in the Philippines,{' '}
            <span className="highlighted">
              PRIME commits 10% of the net subscription proceeds
            </span>{' '}
            from this compendium initiative. Our front-liners shall receive
            essentials such as personal protective equipment, 70% ethyl alcohol
            and canned products.
          </div>
        </Col>
      </Col>
    </Row>
  </StyledOurPledge>
)

const OurPledgeComponent = ({ location, volumes }) => {
  const urlParams = queryString.parse(location.search)
  return (
    <StyledDiv>
      <OtherVolumeSection volumes={volumes} urlParams={urlParams} />
      <OurPledgeSection />
    </StyledDiv>
  )
}

OurPledgeComponent.propTypes = {}

export default OurPledgeComponent
