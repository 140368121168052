import React from 'react'
import styled from 'styled-components'
import { Row, Col, Divider } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'

import navigatingThroughTidesCover from '../../static/images/vol1-navigating-through-tides.png'

const StyledWrapper = styled.div`
  padding: 50px 0px;
  background-color: #ecececf0;

  .content-title {
    font-weight: bold;
    color: ${props => props.theme.bluePalette.original};
  }
  .release-date {
    line-height: 1;
    font-size: 0.9em;
    span {
      background-color: ${props => props.theme.primeBluePalette.original};
      padding: 8px 14px;
      border-radius: 50px;
      color: white;
    }
  }

  .package-title {
    font-weight: 900;
    color: ${props => `${props.theme.blackPalette.original}E6`};
  }

  .ant-divider.ant-divider-horizontal {
    background: ${props => `${props.theme.blackPalette.original}E6`};
  }

  .publication-cover {
    max-width: 100%;
    margin-bottom: 0px;
  }
`

function NavigatingThroughTidesComponent() {
  return (
    <StyledWrapper>
      <StickyContainer>
        <Row type="flex" justify="center">
          <Col
            xs={0}
            sm={0}
            md={7}
            lg={7}
            xl={{ span: 6, offset: 2 }}
            xxl={{ span: 5, offset: 2 }}
          >
            <Sticky topOffset={-105}>
              {({ style, distanceFromBottom }) => {
                const topPosition =
                  distanceFromBottom > 114 ? { top: '114px' } : null
                return (
                  <header style={{ ...style, ...topPosition }}>
                    <img
                      className="publication-cover"
                      src={navigatingThroughTidesCover}
                      alt="Navigating Through Tides – Philippine Real Estate Outlook & Sentiments amid Lockdown"
                    />
                  </header>
                )
              }}
            </Sticky>
          </Col>

          <Col xs={18} sm={18} md={0} lg={0} xl={{ span: 0 }} xxl={{ span: 0 }}>
            <img
              className="publication-cover"
              src={navigatingThroughTidesCover}
              alt="Navigating Through Tides – Philippine Real Estate Outlook & Sentiments amid Lockdown"
            />
          </Col>

          <Col
            xs={22}
            sm={22}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 9, offset: 1 }}
            xxl={{ span: 9, offset: 1 }}
          >
            <br />
            <div className="release-date">
              <span>VOLUME 1 (April 17, 2020)</span>
            </div>
            <br />
            <div>
              <h3 className="package-title">
                NAVIGATING THROUGH TIDES: PHILIPPINE REAL ESTATE OUTLOOK AND
                SENTIMENTS AMID LOCKDOWN
              </h3>
              <p>
                Learn the sentiments and insights of nearly 500 landlords,
                occupiers and investors on the impact of COVID-19 in the overall
                economic and real estate business in the Philippines.
              </p>
            </div>
            <Divider />
            <p className="content-title">
              Global Market and Business Situationer
            </p>
            <p className="content-details">
              Complete high-level analysis of industry performances amid
              COVID-19 and how these sectors affect the local businesses in the
              Philippines
            </p>

            <p className="content-title">
              Philippine Real Estate Business Performance
            </p>
            <p className="content-details">
              Know how businesses of nearly 500 landlords, occupiers and
              investors have performed since the enhanced community quarantine
              was enforced
            </p>

            <p className="content-title">
              Philippine Real Estate Business Outlook
            </p>
            <p className="content-details">
              Charts and extensive analyses of the calculated impacts of
              COVID-19 and recovery projections from more than 1,000 data points
              from the survey
            </p>

            <p className="content-title">Property Sector Bull and Bear Meter</p>
            <p className="content-details">
              Get to know what more than 100 investors have to say about the
              property sectors in the Philippines and how the overall market
              would be shaped during and after the COVID-19 crisis
            </p>

            <p className="content-title">Real Property Buy-Sell Spectrum</p>
            <p className="content-details">
              With influence of over 1,000 real estate stakeholders in the
              Philippines, this buy-sell spectrum serves as a powerful tool to
              guide your next property ventures.
            </p>

            <p className="content-title">
              Geospatial Investor Confidence Mapping
            </p>
            <p className="content-details">
              Be the first to know the next real estate hotspots in the
              Philippines. From 7 major geolocation clusters in the country,
              know where 2 out of 5 investor plan to place their bets within the
              year.
            </p>

            <p className="content-title">
              Occupier and Investor Business Continuity Overview
            </p>
            <p className="content-details">
              Immerse yourself with the movements in the expansion plans of 300
              occupiers and investors in the Philippines – how the crisis
              disrupted the timelines and priorities of these stakeholders
            </p>

            <p className="content-title">
              Exclusive access to the complete and raw survey results and
              derived statistics
            </p>
            <p className="content-details">
              Brimming with over 1,000 insights and derived information from
              nearly 500 landlords, occupiers and investors, this exclusive
              access can equip your real estate business to counter the adverse
              effects of this crisis
            </p>
          </Col>
        </Row>
      </StickyContainer>
    </StyledWrapper>
  )
}

export default NavigatingThroughTidesComponent
